<template>
  <ValidationObserver ref="form">
    <app-dialog
      v-model="open"
      title="adminActions.importStatesOfAffairs.title"
      primary-action-text="navigation.send"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="loading"
      @submit="onSubmit"
      @close="onClose"
    >
      <app-alert
        :value="!!error"
        text="adminActions.importStatesOfAffairs.importFailed"
        class="mb-4"
      />

      <p
        v-html="
          $t('adminActions.importStatesOfAffairs.sourceInfo', {
            link: sourceLink
          })
        "
      ></p>

      <app-alert color="info" :icon="false" outlined class="subtitle-1 mt-6">
        <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
        <span v-html="$t('adminActions.importStatesOfAffairs.convertTable')"></span>
      </app-alert>

      <v-container class="px-0">
        <v-row>
          <v-col>
            <app-file-input
              v-model="file"
              accept=".csv"
              :name="$t('file')"
              placeholder="adminActions.importStatesOfAffairs.selectCsv"
              prepend-inner-icon
              required
              mode="lazy"
            />
          </v-col>
        </v-row>
      </v-container>
    </app-dialog>

    <app-dialog
      v-if="cleanUp === true"
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="adminActions.importStatesOfAffairs.imported"
      text="adminActions.importStatesOfAffairs.importSucceeded"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
    <app-dialog
      v-else
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="adminActions.importStatesOfAffairs.importedCleanUpFailed"
      text="adminActions.importStatesOfAffairs.importSucceededCleanUpFailed"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
const FILE_LINK = 'https://wiki.move-elevator.de/x/_yRPGw';

import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'ImportStatesOfAffairsDialog',

  mixins: [ShowDialogMixin],

  data: () => ({
    file: null,
    cleanUp: true
  }),

  computed: {
    sourceLink() {
      return FILE_LINK;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['importStatesOfAffairsCsv']),

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      let formData = new FormData();
      formData.append('file', this.file);

      const { error, data } = await this.importStatesOfAffairsCsv(formData);

      // eslint-disable-next-line no-console
      console.info(data);

      this.loading = false;

      this.cleanUp = true;
      if (data?.cleanUp === false) {
        this.cleanUp = false;
      }

      if (error) {
        this.error = error;
        return;
      }

      this.submitted = true;
      this.resetForm();
    },

    onClose() {
      this.resetForm();
      this.$emit('input', false);
    },

    resetForm() {
      setTimeout(() => {
        this.file = null;
        this.error = false;
        this.$refs.form?.reset();
      }, 200);
    }
  }
};
</script>
