<template>
  <ValidationObserver ref="form">
    <app-dialog
      v-model="open"
      title="adminActions.externalVoucher.create"
      primary-action-text="navigation.send"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="loading"
      width="80vw"
      @submit="onSubmit"
      @close="onClose"
    >
      <app-alert v-if="!!error">
        {{ $t('errorOccured') }}:
        <p v-if="error.type" class="mt-4">
          {{ $t(`adminActions.externalVoucher.errors.${error.type}`) }}
        </p>
        <p v-if="error.content" class="mt-4">
          {{ error.content }}
        </p>
      </app-alert>

      <v-container class="px-0">
        <v-row>
          <v-col cols="6">
            <app-text-field
              id="create-external-source__sap-number"
              v-model="formData.creditorSapNumber"
              :label="$t('sapNumber')"
              required
            />
          </v-col>
          <v-col cols="6">
            <app-select
              v-model="formData.source"
              :items="externalSourceItems"
              label="adminActions.externalVoucher.source"
              name="adminActions.externalVoucher.source"
              required
            />
          </v-col>
        </v-row>

        <v-row class="mt-10">
          <v-col class="subtitle">{{ $t('adminActions.externalVoucher.voucherData') }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <app-text-field
              v-model="formData.voucher.customNumber"
              :hint="hintVoucherNumberFormat"
              label="vouchers.customNumber"
              persistent-hint
              required
            />
          </v-col>
          <v-col cols="6">
            <app-file-input
              v-model="formData.voucher.file"
              label="voucher"
              rules="required|ext:pdf"
              append-icon
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <app-date-picker
              v-model="formData.voucher.voucherDate"
              label="vouchers.voucherDate"
              placeholder="dateHint"
              append-icon
              required
            />
          </v-col>
          <v-col cols="6">
            <app-date-picker
              v-model="formData.voucher.performanceDate"
              label="vouchers.performanceDate"
              placeholder="dateHint"
              append-icon
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <app-number-field
              v-model="formData.voucher.amount"
              label="amount"
              append-icon="mdi-currency-eur"
              placeholder="0,00"
              required
            />
          </v-col>
          <v-col cols="6">
            <app-number-field
              v-model="formData.voucher.initialLeftoverAmount"
              label="vouchers.initialLeftover"
              append-icon="mdi-currency-eur"
              placeholder="0,00"
              required
              :rules="`max_currency:${formData.voucher.amount}`"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <app-text-field
              v-model="formData.voucher.serviceFeeRate"
              append-icon="mdi-percent-outline"
              label="serviceFees.fee"
              rules="percent"
              max="100"
              min="0"
              step="0.1"
              type="number"
            />
          </v-col>
          <v-col cols="6">
            <app-text-field
              v-model="formData.voucher.customisedLabel"
              type="string"
              label="Kennzeichen"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" class="caption pb-0">
            {{ $t('adminActions.externalVoucher.termsOfPayment') }}
          </v-col>
          <v-col>
            <app-text-field
              v-model="formData.voucher.termsOfPayment.discountRate"
              type="number"
              label="Skonto"
              required
            />
          </v-col>
          <v-col>
            <app-text-field
              v-model="formData.voucher.termsOfPayment.days"
              type="number"
              label="Tage"
              required
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-10">
          <v-col class="subtitle text-uppercase">
            {{ $t('adminActions.externalVoucher.debtorData') }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <app-radio-group
              v-model="formData.debtor.type"
              label="debtors.debtorType"
              :items="debtorTypeItems"
              name="debtors.debtorType"
              required
            />
          </v-col>
        </v-row>
        <template v-if="formData.debtor.type">
          <v-row v-if="businessDebtorSelected">
            <v-col cols="12">
              <app-text-field v-model="formData.debtor.name" label="company" required />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="businessDebtorSelected" cols="12" class="caption pb-0">
              {{ $t('contactPerson') }}
            </v-col>
            <v-col cols="2">
              <app-salutation-select v-model="formData.debtor.salutation" />
            </v-col>
            <v-col cols="5">
              <app-text-field
                v-model="formData.debtor.firstName"
                label="firstName"
                :required="!businessDebtorSelected"
              />
            </v-col>
            <v-col cols="5">
              <app-text-field
                v-model="formData.debtor.lastName"
                label="lastName"
                :required="!businessDebtorSelected"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <app-text-field v-model="formData.debtor.street" label="street" required />
            </v-col>
            <v-col cols="4">
              <app-text-field v-model="formData.debtor.house" label="house" required />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <app-text-field
                v-model="formData.debtor.zip"
                label="zipCode"
                rules="required|numeric|length: 5"
                required
              />
            </v-col>
            <v-col cols="8">
              <app-text-field v-model="formData.debtor.city" label="city" required />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <app-text-field
                v-model="formData.debtor.customNumber"
                label="debtors.customDebtorNumber"
              />
            </v-col>
            <v-col cols="6">
              <app-text-field v-model="formData.debtor.email" label="email" rules="email" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <app-text-field v-model="formData.debtor.emailCc" label="emailCc" rules="email" />
            </v-col>
            <v-col cols="6">
              <app-text-field v-model="formData.debtor.emailBcc" label="emailBcc" rules="email" />
            </v-col>
          </v-row>
          <v-row v-if="!businessDebtorSelected">
            <v-col cols="6">
              <app-date-picker
                v-model="formData.debtor.dateOfBirth"
                label="dateOfBirth"
                height="50"
                append-icon
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <app-text-field
                v-model="formData.debtor.externalIdentifier"
                label="adminActions.externalVoucher.externalIdentifier"
              />
            </v-col>
            <v-col cols="6">
              <app-select
                v-model="formData.debtor.language"
                :items="languageKeyItems"
                label="adminActions.externalVoucher.languageKey"
              />
            </v-col>
          </v-row>
        </template>
      </v-container>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="adminActions.externalVoucher.created"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import AppSalutationSelect from '@/shared/components/AppSalutationSelect';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { DEBTOR_TYPES, DebtorType } from '@/modules/Debtors/statics/debtorType';
import {
  EXTERNAL_VOUCHER_SOURCES,
  ExternalVoucherSource
} from '@/modules/Admin/statics/externalVoucherSource';
import {
  ERROR_CODE_MISSING_VOUCHER_FILE,
  ERROR_CODE_INVALID_CREDITOR,
  ERROR_CODE_BNET_ERROR
} from '@/statics/errorCodes';
import { LANGUAGE_KEYS } from '@/modules/Admin/statics/languageKey';
import { MILLISECONDS_PER_WEEK } from '@/statics/timePeriods';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions, mapState } from 'vuex';
import { formatTimestampToDate } from '@/helper/filter/formatDate';

export default {
  name: 'CreateExternalVoucherDialog',

  components: { AppSalutationSelect },

  mixins: [ShowDialogMixin],

  data() {
    return {
      formData: this.createFormData(),
      error: null
    };
  },

  computed: {
    ...mapState(NAMESPACE, { items: 'creditorNames' }),
    externalSourceItems() {
      return EXTERNAL_VOUCHER_SOURCES.map((source) => ({
        label: this.$t(`adminActions.externalVoucher.sourceOptions.${source}`),
        value: source
      }));
    },
    debtorTypeItems() {
      return DEBTOR_TYPES.map((type) => ({
        label: this.$t(`debtors.debtorTypes.${type}`),
        value: type
      }));
    },
    businessDebtorSelected() {
      return this.formData.debtor.type === DebtorType.BUSINESS;
    },
    hintVoucherNumberFormat() {
      if (this.formData.source === ExternalVoucherSource.FACTORIX) {
        return this.$t('adminActions.externalVoucher.hintVoucherFormat');
      }

      return '';
    },
    languageKeyItems() {
      return LANGUAGE_KEYS.map((key) => ({ label: key, value: key }));
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['createExternalVoucher']),

    createFormData() {
      return {
        creditorSapNumber: '9200000026',
        source: ExternalVoucherSource.DEGENER,
        voucher: {
          file: null,
          customNumber: '73',
          voucherDate: formatTimestampToDate((Date.now() - MILLISECONDS_PER_WEEK) / 1000),
          performanceDate: formatTimestampToDate((Date.now() - MILLISECONDS_PER_WEEK) / 1000),
          amount: 10350,
          initialLeftoverAmount: 10350,
          serviceFeeRate: '',
          termsOfPayment: {
            discountRate: 0,
            days: 14
          },
          customisedLabel: ''
        },
        debtor: {
          type: DebtorType.BUSINESS,
          name: 'Sommer Tiefbau GmbH',
          contact: 'Max Mustermann',
          salutation: 'Herr',
          firstName: 'Maik',
          lastName: 'Nussbaum',
          dateOfBirth: '1971-01-02',
          street: 'Thurnreuth',
          house: '50',
          zip: '94110',
          city: 'Wegscheid',
          email: 'test-mail@upload-extern.de',
          emailCc: 'test-mailcc@upload-extern.de',
          emailBcc: 'test-mailbcc@upload-extern.de',
          customNumber: '987123',
          language: '',
          externalIdentifier: ''
        }
      };
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return this.scrollTo(document.querySelector('.input--has-error'));
      }

      this.loading = true;
      const formData = this.getFormData();

      const { error } = await this.createExternalVoucher(formData);

      this.loading = false;
      if (error) {
        switch (error.response?.data.error) {
          case ERROR_CODE_BNET_ERROR:
            this.error = { type: 'errorBnet', content: error.response?.data.content };
            break;
          case ERROR_CODE_INVALID_CREDITOR:
            this.error = { type: 'errorInvalidCreditor' };
            break;
          case ERROR_CODE_MISSING_VOUCHER_FILE:
            this.error = { type: 'errorMissingVoucherFile' };
            break;
          default:
            this.error = { content: error.response?.data.content };
        }

        return this.scrollTo(0);
      }

      this.submitted = true;
      this.resetForm();
    },

    getFormData() {
      let formData = new FormData();

      formData.append('creditorSapNumber', this.formData.creditorSapNumber);
      formData.append('source', this.formData.source);
      formData.append('file', this.formData.voucher.file);

      formData.append('voucher[customNumber]', this.formData.voucher.customNumber);
      formData.append('voucher[voucherDate]', this.formData.voucher.voucherDate);
      formData.append('voucher[performanceDate]', this.formData.voucher.performanceDate);
      formData.append('voucher[amount]', this.formatAmount(this.formData.voucher.amount));
      formData.append('voucher[customisedLabel]', this.formData.voucher.customisedLabel);
      formData.append(
        'voucher[initialLeftoverAmount]',
        this.formatAmount(this.formData.voucher.initialLeftoverAmount)
      );
      formData.append('voucher[gebuehr]', this.formData.voucher.serviceFeeRate);
      formData.append(
        'voucher[termsOfPayment][discountRate]',
        this.formData.voucher.termsOfPayment.discountRate
      );
      formData.append('voucher[termsOfPayment][days]', this.formData.voucher.termsOfPayment.days);

      if (this.businessDebtorSelected) {
        formData.append('debtor[name]', this.formData.debtor.name);
        formData.append('debtor[salutation]', this.formData.debtor.salutation);
        formData.append(
          'debtor[contact]',
          `${this.formData.debtor.firstName} ${this.formData.debtor.lastName}`
        );
      } else {
        formData.append('debtor[salutation]', this.formData.debtor.salutation);
        formData.append(
          'debtor[name]',
          `${this.formData.debtor.firstName} ${this.formData.debtor.lastName}`
        );
        formData.append('debtor[dayOfBirth]', this.formData.debtor.dateOfBirth);
      }
      formData.append('debtor[type]', this.formData.debtor.type);
      formData.append('debtor[street]', this.formData.debtor.street);
      formData.append('debtor[house]', this.formData.debtor.house);
      formData.append('debtor[zip]', this.formData.debtor.zip);
      formData.append('debtor[city]', this.formData.debtor.city);
      formData.append('debtor[email]', this.formData.debtor.email);
      formData.append('debtor[emailCc]', this.formData.debtor.emailCc);
      formData.append('debtor[emailBcc]', this.formData.debtor.emailBcc);
      formData.append('debtor[customNumber]', this.formData.debtor.customNumber);
      formData.append('debtor[externalIdentifier]', this.formData.debtor.externalIdentifier);
      formData.append('debtor[language]', this.formData.debtor.language);

      return formData;
    },

    formatAmount(number, fractionDigits = 2) {
      if (isNaN(parseFloat(number))) {
        return '';
      }

      return (number / 100).toFixed(fractionDigits);
    },

    onClose() {
      this.resetForm();
      this.$emit('input', false);
    },

    resetForm() {
      setTimeout(() => {
        this.formData = this.createFormData();
        this.$refs.form?.reset();
        this.error = null;
      }, 200);
    },

    scrollTo(target) {
      this.$vuetify.goTo(target, {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
        container: '.v-dialog__content.v-dialog__content--active .scroll-overflow'
      });
    }
  }
};
</script>
