// authentication
export const ERROR_CODE_OLD_PASSWORD = 1585833529;
export const ERROR_CODE_REQUEST_EXPIRED = 1569495621;

// create creditor
export const ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED = 1574933623;
export const ERROR_CODE_CREDITOR_EMAIL_ALREADY_ASSIGNED = 1579926153;
export const ERROR_CODE_DDM_OR_CSP_ALREADY_ASSIGNED = 1577926153;

// create external voucher
export const ERROR_CODE_MISSING_VOUCHER_FILE = 1625057542;
export const ERROR_CODE_INVALID_CREDITOR = 1625057543;
export const ERROR_CODE_BNET_ERROR = 1625057544;
