<template>
  <app-select
    :items="salutationItems"
    :vid="$attrs.id"
    label="salutation"
    required
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'AppSalutationSelect',

  computed: {
    salutationItems() {
      return Object.keys(this.$t('salutationOptions')).map((option) => ({
        label: this.$t(`salutationOptions.${option}`),
        value: this.$t(`salutationOptions.${option}`)
      }));
    }
  }
};
</script>

<style scoped>
>>> .v-select label {
  color: var(--c-grey);
  font-size: 16px;
}

>>> .v-select__selection {
  font-size: 16px;
}
</style>
