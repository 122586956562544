<template>
  <v-container fluid pa-0 pt-8>
    <v-card v-if="actions.length === 0" elevation="12">
      <v-card-title>{{ $t('adminActions.noActionAvailable') }}</v-card-title>
    </v-card>

    <v-row v-else align="stretch">
      <v-col v-for="(action, index) in actions" :key="index" cols="4">
        <v-card elevation="12" class="pa-2 fill-height d-flex flex-column">
          <v-card-title class="action-card__title">
            <span class="text-h5 d-inline-block mr-4">{{ $t(action.title) }}</span>
            <component :is="action.icon" class="action-card__icon" />
          </v-card-title>
          <v-card-text class="mt-2 body-1">{{ $t(action.text) }}</v-card-text>
          <v-spacer />
          <v-card-actions class="d-flex justify-end">
            <app-btn title="navigation.start" :to="action.to" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <component :is="selectedAction.component" v-if="selectedAction" v-model="showActionDialog" />
  </v-container>
</template>

<script>
import CreateExternalVoucherDialog from '@/modules/Admin/components/AdminActions/CreateExternalVoucherDialog';
import IconAssignDebtor from '@/shared/components/icons/IconAssignDebtor.vue';
import ImportStatesOfAffairsDialog from '@/modules/Admin/components/AdminActions/ImportStatesOfAffairsDialog';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'AdminActions',

  mixins: [ShowDialogMixin],

  components: { IconAssignDebtor },

  computed: {
    actions() {
      return this.filterItemsWithFulfilledCondition([
        {
          param: this.queryParam.IMPORT_STATES_OF_AFFAIRS,
          title: 'adminActions.importStatesOfAffairs.title',
          text: 'adminActions.importStatesOfAffairs.info',
          icon: 'icon-csv-import',
          component: ImportStatesOfAffairsDialog,
          to: {
            query: { ...this.$route.query, [this.queryParam.IMPORT_STATES_OF_AFFAIRS]: true }
          },
          condition: this.isSuperAdmin
        },
        {
          param: this.queryParam.CREATE_EXTERNAL_VOUCHER,
          title: 'adminActions.externalVoucher.create',
          text: 'adminActions.externalVoucher.info',
          icon: 'icon-upload-invoice',
          component: CreateExternalVoucherDialog,
          to: {
            query: { ...this.$route.query, [this.queryParam.CREATE_EXTERNAL_VOUCHER]: true }
          },
          condition: !this.isProduction
        },
        {
          title: 'adminActions.assignDebtor.title',
          text: 'adminActions.assignDebtor.info',
          icon: 'icon-assign-debtor',
          to: { name: this.routeName.ASSIGN_DEBTORS },
          condition: this.hasAssignDebtorPermission
        },
        {
          title: 'adminActions.actionLog.title',
          text: 'adminActions.actionLog.info',
          icon: '', // TODO
          to: { name: this.routeName.ACTION_LOG },
          condition: this.hasViewActionLogPermission
        }
      ]);
    },
    showActionDialog: {
      get() {
        return !!this.selectedAction.component;
      },
      set() {
        if (!this.selectedAction) {
          return;
        }
        this.setQueryParam(this.selectedAction.param, undefined);
      }
    },
    selectedAction() {
      return this.actions.find(
        (action) =>
          this.$route.query[action.param] &&
          this.hasFulfilledCondition(action) &&
          (action.permission === undefined || action.permission === true)
      );
    }
  },

  methods: {
    onClose() {
      this.$emit('input', false);
    }
  }
};
</script>

<style scoped lang="scss">
.page__container {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 48px 32px;
}

.action-card__title {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  word-break: break-word;
}

.action-card__icon {
  margin-left: auto;
  margin-right: -8px;
  height: 36px;
  width: auto;
  flex: 0 0 auto;
}
</style>
