<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 29 29"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
  >
    <g fill="currentColor" transform="matrix(1,0,0,1,-0.783468,-0.841063)">
      <g transform="matrix(0.677129,0,0,0.677129,-0.44579,-1.06532)">
        <path
          d="M12.815,16.29C13.733,16.29 14.607,16.426 15.442,16.662C15.699,16.712 15.907,16.902 15.98,17.155C16.053,17.408 15.977,17.68 15.786,17.86C15.594,18.04 15.318,18.096 15.071,18.006C14.342,17.8 13.591,17.689 12.815,17.689L12.117,17.689C7.318,17.689 3.396,21.785 3.396,26.783L21.535,26.783C21.535,23.5 19.844,20.575 17.327,18.979C17.095,18.857 16.951,18.614 16.954,18.351C16.958,18.088 17.108,17.849 17.343,17.733C17.578,17.616 17.858,17.642 18.069,17.799C20.995,19.655 22.93,23.018 22.93,26.783C22.93,27.031 22.934,27.246 22.919,27.515C22.902,27.888 22.595,28.182 22.222,28.182L2.71,28.182C2.333,28.182 2.024,27.882 2.012,27.504C2.004,27.298 1.997,27.035 2.001,26.783L2.001,26.761C2.013,21.037 6.535,16.29 12.117,16.29L12.117,16.29L12.815,16.29ZM21.22,11.22C21.513,10.927 21.988,10.927 22.281,11.22L23.911,12.851L26.637,10.123C26.93,9.83 27.539,9.697 27.832,9.99C28.125,10.283 28.006,10.877 27.713,11.17L24.971,13.911L23.911,14.972L21.22,12.281C20.927,11.988 20.927,11.513 21.22,11.22ZM12.466,3C15.732,3 18.396,5.67 18.396,8.946C18.396,12.221 15.732,14.891 12.466,14.891C9.199,14.891 6.536,12.221 6.536,8.946C6.536,5.67 9.199,3 12.466,3ZM12.466,4.399C9.953,4.399 7.931,6.426 7.931,8.946C7.931,11.465 9.953,13.492 12.466,13.492C14.978,13.492 17,11.465 17,8.946C17,6.426 14.978,4.399 12.466,4.399Z"
          style="fill-rule: nonzero; stroke: black; stroke-width: 0.37px"
        />
      </g>
      <g transform="matrix(0.647316,0,0,0.647316,12.0642,11.8552)">
        <path
          d="M8.235,0.001C8.063,0.016 7.903,0.096 7.788,0.224L7.777,0.235C7.773,0.242 7.77,0.249 7.767,0.256C7.755,0.267 7.745,0.277 7.735,0.288C7.731,0.295 7.727,0.302 7.724,0.31C7.716,0.32 7.709,0.331 7.703,0.341C7.699,0.352 7.695,0.363 7.692,0.373C7.685,0.384 7.677,0.395 7.671,0.405C7.667,0.412 7.663,0.42 7.66,0.427C7.652,0.441 7.645,0.455 7.639,0.469C7.639,0.476 7.639,0.484 7.639,0.491C7.635,0.505 7.631,0.519 7.628,0.533C7.615,0.585 7.607,0.639 7.607,0.693L7.607,25.792C7.607,26.168 7.912,26.474 8.289,26.474L25.568,26.474C25.944,26.474 26.25,26.168 26.25,25.792L26.25,7.415C26.25,7.141 26.086,6.894 25.834,6.787L19.698,0.214C19.568,0.076 19.387,-0.001 19.197,0.001L8.299,0.001C8.278,-0 8.257,-0 8.235,0.001M8.97,1.364L18.59,1.364L18.59,6.201C18.59,7.146 19.261,8.086 20.295,8.086L24.886,8.097L24.886,25.11L8.97,25.11L8.97,1.364M19.954,2.483L23.917,6.733L20.295,6.723C20.145,6.723 19.954,6.512 19.954,6.201L19.954,2.483M11.872,11.925C11.629,11.937 11.41,12.077 11.298,12.294C11.186,12.511 11.198,12.771 11.33,12.976C11.462,13.181 11.693,13.301 11.936,13.289L15.889,13.31C16.134,13.315 16.363,13.188 16.489,12.976C16.614,12.765 16.616,12.503 16.494,12.29C16.372,12.077 16.145,11.946 15.899,11.947L11.936,11.925C11.915,11.924 11.894,11.924 11.872,11.925M11.872,16.003C11.628,16.015 11.409,16.155 11.297,16.372C11.185,16.589 11.198,16.849 11.329,17.054C11.461,17.259 11.692,17.378 11.936,17.367L21.896,17.399C22.142,17.404 22.371,17.276 22.496,17.065C22.622,16.854 22.624,16.592 22.502,16.379C22.38,16.165 22.153,16.034 21.907,16.035L11.936,16.003C11.914,16.002 11.893,16.002 11.872,16.003M11.936,20.16C11.69,20.156 11.462,20.285 11.338,20.498C11.214,20.71 11.214,20.973 11.338,21.185C11.462,21.398 11.69,21.527 11.936,21.523L21.897,21.555C22.143,21.56 22.372,21.433 22.497,21.221C22.622,21.01 22.624,20.748 22.502,20.535C22.38,20.322 22.153,20.191 21.908,20.192L11.936,20.16"
          style="fill-rule: nonzero; stroke: black; stroke-width: 0.39px"
        />
      </g>
      <g transform="matrix(9.38745e-16,0.785995,-0.785995,9.38745e-16,22.4912,13.6921)">
        <path
          d="M8.981,21.777L11.856,21.777C12.946,21.777 13.631,21.565 14.049,21.146C14.466,20.727 14.678,20.038 14.678,18.942L14.678,11.714L12.38,14.02C12.25,14.15 12.075,14.218 11.903,14.218C11.734,14.218 11.559,14.151 11.43,14.023C11.429,14.022 11.429,14.022 11.428,14.021C11.168,13.76 11.167,13.326 11.428,13.064L14.545,9.937L14.546,9.937C14.784,9.7 15.044,9.531 15.349,9.531C15.655,9.531 15.918,9.699 16.154,9.937L19.269,13.064C19.531,13.326 19.531,13.76 19.269,14.021C19.009,14.281 18.579,14.283 18.319,14.02L16.023,11.716L16.023,18.942C16.023,20.326 15.711,21.393 15.001,22.106C14.292,22.819 13.232,23.131 11.854,23.131L8.981,23.131C8.452,23.131 8.451,21.777 8.981,21.777Z"
          style="stroke: black; stroke-width: 0.32px; stroke-linecap: round; stroke-miterlimit: 1.5"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconAssignDebtor'
};
</script>
